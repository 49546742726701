import React, { FC, useEffect } from 'react'
import { ButtonGroup, Grid } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import useForm, { ValidationRule } from '@hooks/useForm'
import GenericFormPage from '@templates/Generic/Form'
import {
  TFormAddJumpHost,
  initialValues,
  initialValidation,
  FormAddJumpHost
} from '@components/Form/FormAddJumpHost'
import {
  deleteJumpHost,
  getJumpHost,
  JumpHostResponse,
  updateJumpHost
} from '@utils/api/jumphosts'
import useApi, { wasSuccess } from '@hooks/useApi'
import { navigate, PageProps } from 'gatsby'
import { ApiResponse } from '@utils/api'
import { Delete } from '@mui/icons-material'

const HostsJumpHostAddPage: FC<PageProps> = ({ params }) => {
  const getApi = useApi<JumpHostResponse>({
    apiMethod: getJumpHost,
    params: params.id
  })

  const saveApi = useApi<ApiResponse<string>>({
    apiMethod: updateJumpHost,
    requestOnMount: false
  })

  const deleteApi = useApi({
    apiMethod: deleteJumpHost,
    requestOnMount: false
  })

  const handleDelete = () => {
    deleteApi.makeRequest(params.id).then((response) => {
      if (wasSuccess(response)) {
        deleteApi.enqueueSnackbar('Jump host successfully deleted.', {
          variant: 'success'
        })

        navigate('/hosts/jump-hosts/')
      }
    })
  }

  const { response, loading } = getApi

  const form = useForm<TFormAddJumpHost>({
    initialValues: {
      ...initialValues,
      name: params.id
    },
    initialValidationRules: {
      name: initialValidation['name']
    } as ValidationRule<TFormAddJumpHost>
  })

  useEffect(() => {
    if (response) {
      form.setValues({
        ...initialValues,
        ...response
      })
    }
  }, [response])

  return (
    <GenericFormPage
      awaitingData={loading}
      maxWidth="md"
      title="Add Jump Host"
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button onClick={() => handleDelete()} endIcon={<Delete />}>
            Delete
          </Button>
        </ButtonGroup>
      }
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: 'Jump Hosts',
          to: '/hosts/jump-hosts/'
        },
        {
          title: 'Add Jump Host'
        }
      ]}
      api={saveApi}
      form={form as any}
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={saveApi.loading}
            >
              {'Update Jump Host'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'Cancel'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormAddJumpHost form={form as any} edit={true} />
    </GenericFormPage>
  )
}

export default HostsJumpHostAddPage
